export const spacesQueryKeys = {
	all: () => ['spaces'],
	one: () => ['space'],
	add: () => [...spacesQueryKeys.one(), 'add'],
	delete: () => [...spacesQueryKeys.one(), 'delete'],
	edit: () => [...spacesQueryKeys.one(), 'edit'],
	list: () => [...spacesQueryKeys.all(), 'list'],
	listWithStats: () => [...spacesQueryKeys.all(), 'list-with-stats'],
	listWithWorkAcceptanceStats: () => [...spacesQueryKeys.all(), 'list-with-work-acceptance-stats'],
	details: () => [...spacesQueryKeys.one(), 'detail'],
	detail: (id?: string) => [...spacesQueryKeys.details(), id],
	checkListsData: () => [...spacesQueryKeys.all(), 'check-lists-data'],
	typesCounts: () => [...spacesQueryKeys.all(), 'types-count'],
	typesCount: (objectIds?: string[]) => [...spacesQueryKeys.typesCounts(), objectIds],
	indicators: {
		edit: () => [...spacesQueryKeys.one(), 'indicators', 'edit'],
		details: () => [...spacesQueryKeys.one(), 'indicators', 'detail'],
		detail: (id: string) => [...spacesQueryKeys.indicators.details(), 'detail', id],
		localList: () => [...spacesQueryKeys.one(), 'indicators', 'local-list']
	},
	status: {
		edit: () => [...spacesQueryKeys.one(), 'status', 'edit'],
		details: () => [...spacesQueryKeys.one(), 'status', 'detail'],
		detail: (id: string) => [...spacesQueryKeys.status.details(), 'detail', id],
		localList: () => [...spacesQueryKeys.one(), 'status', 'local-list']
	},
	checks: () => [...spacesQueryKeys.one(), 'checks'],
	spaceChecks: (objectId: string, spaceId: string) => [
		...spacesQueryKeys.checks(),
		objectId,
		spaceId
	],
	presaleChecks: () => [...spacesQueryKeys.one(), 'presaleChecks'],
	spacePresaleChecks: (objectId: string, spaceId: string) => [
		...spacesQueryKeys.presaleChecks(),
		objectId,
		spaceId
	],
	inspections: () => [...spacesQueryKeys.one(), 'inspections'],
	spaceInspections: (objectId: string, spaceId: string) => [
		...spacesQueryKeys.inspections(),
		objectId,
		spaceId
	],
	internalAcceptances: () => [...spacesQueryKeys.one(), 'internalAcceptances'],
	spaceInternalAcceptances: (objectId: string, spaceId: string) => [
		...spacesQueryKeys.internalAcceptances(),
		objectId,
		spaceId
	],
	ownerAcceptances: () => [...spacesQueryKeys.one(), 'ownerAcceptances'],
	spaceOwnerAcceptances: (objectId: string, spaceId: string) => [
		...spacesQueryKeys.ownerAcceptances(),
		objectId,
		spaceId
	],
	problemReplies: () => [...spacesQueryKeys.one(), 'problemReplies'],
	spaceProblemReplies: (objectId: string, spaceId: string) => [
		...spacesQueryKeys.problemReplies(),
		objectId,
		spaceId
	],
	problems: () => [...spacesQueryKeys.one(), 'problems'],
	spaceProblems: (objectId: string, spaceId: string) => [
		...spacesQueryKeys.problems(),
		objectId,
		spaceId
	],
	tasks: () => [...spacesQueryKeys.one(), 'tasks'],
	spaceTasks: (spaceId: string) => [...spacesQueryKeys.tasks(), spaceId],
	warrantyClaims: () => [...spacesQueryKeys.one(), 'warrantyClaims'],
	spaceWarrantyClaims: (objectId: string, spaceId: string) => [
		...spacesQueryKeys.warrantyClaims(),
		objectId,
		spaceId
	],
	generate: () => [...spacesQueryKeys.list(), 'generate'],
	generateById: (objectId: string) => [...spacesQueryKeys.generate(), objectId],
	export: () => [...spacesQueryKeys.list(), 'export'],
	exportById: (objectId: string) => [...spacesQueryKeys.export(), objectId],
	exportOne: () => [...spacesQueryKeys.one(), 'export']
};
