import {getSelectionMessage} from '../../utils/getSelectionMessage';

interface ISelectionMessageProps {
	totalSelectedByCurrentType: number;
	nonEmptySelectedCount: number;
	typeCounts: number;
	canAddMultipleEntities: boolean;
}

export const SelectionMessage = ({
	totalSelectedByCurrentType,
	nonEmptySelectedCount,
	typeCounts,
	canAddMultipleEntities
}: ISelectionMessageProps) => {
	const message = getSelectionMessage({
		totalSelectedByCurrentType,
		nonEmptySelectedCount,
		typeCounts,
		canAddMultipleEntities
	});

	return message ? <p>{message}</p> : null;
};
