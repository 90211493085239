import {
	ITreeUserGroupsItem,
	ITreeUserGroupsItemType,
	makeUserGroupsTreeData
} from '@src/components/EntitiesFilters/utils/makeUserGroupsTreeData';
import {useUsersAsArray, useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {IBriefUser} from '@tehzor/tools/interfaces/users/IBriefUser';
import {IWorkingGroup} from '@tehzor/tools/interfaces/workingGroups/IWorkingGroup';
import {
	flatFilter,
	treeFilter
} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import {memo, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';
import {useUpdateEffect} from 'react-use';
import {debounce} from 'lodash';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import {
	ITreeItemProps,
	LinkButton,
	Select2,
	SelectOption,
	TabLink,
	Tabs,
	TreeSelect,
	TreeSelectOption
} from '@tehzor/ui-components';
import {useRouting} from '@src/components/MobileEntitiesFilters/utils/pagesRouting';
import {TranslatedFilterPage} from '@src/components/TranslatedFilterPage';
import {TFunction} from 'i18next';
import {isLocalStateEqual} from '@src/components/MobileEntitiesFilters/utils/isLocalStateEqual';
import {TranslatedSelectSearch} from '@src/components/TranslatedSelectSearch';
import './ResponsiblesFilterPage.less';

interface IResponsiblesFilterProps {
	objectId?: string;
	entity?: string;
	respUsers?: string[];
	workingGroups?: IWorkingGroup[];
	label?: string;
	onChange: (respUsers?: string[]) => void;
}

const getFilteredData = (tabIndex: number, search: string, users?: IBriefUser[]) => {
	if (tabIndex !== 0 || !users) {
		return undefined;
	}
	return flatFilter(users, 'fullName', search);
};

const getTreeData = (
	tabIndex: number,
	workingGroups?: IWorkingGroup[],
	usersMap?: Record<string, IBriefUser>
) => {
	if (tabIndex !== 1 || !usersMap || !workingGroups?.length) {
		return [];
	}

	return makeUserGroupsTreeData(workingGroups, usersMap);
};

const getFilteredTreeData = (
	tabIndex: number,
	search: string,
	treeData: ITreeUserGroupsItem[],
	changeExpanded: (expanded: string[]) => void
) => {
	if (tabIndex !== 1) {
		return [];
	}

	const {filteredData, expanded} = treeFilter(treeData, 'name', search);
	if (expanded?.length) {
		changeExpanded(expanded.map(item => item.id));
	}

	return filteredData;
};

const getContent = (data: ITreeUserGroupsItem, t: TFunction) => (
	<div className="responsibles-item">
		<div className="responsibles-item-content">{data.content}</div>
		<div className="responsibles-item-additional-content">{data.additionalContent}</div>
		{data.type === ITreeUserGroupsItemType.LEADER && (
			<div className="responsibles-item-leader">
				{t('entitiesFilters.responsiblesFilter.leaderLabel')}
			</div>
		)}
	</div>
);

const getTreeItem = (props: ITreeItemProps<ITreeUserGroupsItem>, t: TFunction) => (
	<TreeSelectOption
		{...props}
		getContent={(data: ITreeUserGroupsItem) => getContent(data, t)}
	/>
);

export const ResponsiblesFilterPage = memo(
	({objectId, entity, respUsers, workingGroups, label, onChange}: IResponsiblesFilterProps) => {
		const {t} = useTranslation();

		const {goBack} = useChangePath();
		const location = useLocation();
		const {canGoBack} = location.state as {canGoBack?: boolean};

		const [selectedUsers, setSelectedUsers] = useState(respUsers);
		const [expandedUsers, setExpandedUsers] = useState<string[]>([]);

		useUpdateEffect(() => setSelectedUsers(respUsers), [respUsers]);

		const [tabIndex, setTabIndex] = useState(0);
		const links = [
			<TabLink label={t('entitiesFilters.responsiblesFilter.tabLink.byUser')} />,
			<TabLink label={t('entitiesFilters.responsiblesFilter.tabLink.byWorkingGroup')} />
		];

		const filterLabel = label ?? t('entitiesFilters.responsiblesFilter.label');

		const [search, setSearch] = useState('');
		const changeSearch = useMemo(() => debounce(setSearch, 300), []);
		const clearSearch = useCallback(() => setSearch(''), []);

		const {data: users} = useUsersAsArray();
		const filteredData = getFilteredData(tabIndex, search, users);

		const {data: usersMap} = useUsersAsMap();
		const treeData = getTreeData(tabIndex, workingGroups, usersMap);

		const treeFilteredData = getFilteredTreeData(tabIndex, search, treeData, setExpandedUsers);

		const handleApply = () => {
			onChange(selectedUsers);
			goBack();
		};

		const handleChange = (v?: string[]) => {
			setSelectedUsers(v);
		};

		const handleClear = useCallback(() => {
			setSelectedUsers([]);
			clearSearch();
		}, [clearSearch]);

		const handleChangeTab = (index: number) => {
			handleClear();
			setTabIndex(index);
		};

		useAppHeader(
			{
				title: filterLabel,
				showBackBtn: true,
				mobileRightButtons: selectedUsers?.length ? (
					<LinkButton
						label={t('entitiesFilters.cancelButton.label')}
						onClick={handleClear}
					/>
				) : null
			},
			[selectedUsers]
		);

		useRouting(!canGoBack, objectId, entity);

		const getUsersTabContent = () => {
			if (tabIndex !== 0 || filteredData === undefined) {
				return null;
			}

			return (
				<Select2
					multiple
					value={selectedUsers}
					onChange={handleChange}
				>
					{filteredData?.map(item => (
						<SelectOption
							key={item.id}
							itemKey={item.id}
							content={item.fullName}
						/>
					))}
				</Select2>
			);
		};

		const getWorkingGroupsTabContent = () => {
			if (tabIndex !== 1 || treeFilteredData === undefined) {
				return null;
			}

			return (
				<TreeSelect
					data={treeFilteredData}
					multiple
					value={selectedUsers}
					onChange={handleChange}
					expandedValue={expandedUsers}
					onExpand={setExpandedUsers}
					TreeItem={props => getTreeItem(props, t)}
				/>
			);
		};

		return (
			<TranslatedFilterPage
				applyDisabled={isLocalStateEqual(respUsers, selectedUsers)}
				onApplyClick={handleApply}
				tabs={
					<Tabs
						links={links}
						activeTab={tabIndex}
						onActiveTabChange={handleChangeTab}
					/>
				}
				search={
					<TranslatedSelectSearch
						value={search}
						onChange={changeSearch}
					/>
				}
			>
				{getUsersTabContent()}
				{getWorkingGroupsTabContent()}
			</TranslatedFilterPage>
		);
	}
);
