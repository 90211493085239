import {httpRequests} from '@tehzor/tools/api/httpRequests';
import getTimezone from '@tehzor/tools/utils/getTimezone';
import {IDefferedExportResponse, IExportReplyRequest} from '@tehzor/tools/interfaces/export';
import getEmailsArray from '@src/utils/getEmailsArray';

/**
 * Отправляет запрос на экспорт официального ответа
 *
 * @param templateId id шаблона экспорта
 * @param createDocument флаг создания документа
 * @param email email для отправки
 * @param objectId id объекта
 * @param commentId id комментария
 */
export const makeReplyExportRequest = async (
	templateId: string,
	createDocument: boolean | undefined,
	email: string | undefined,
	objectId: string,
	commentId: string
): Promise<IDefferedExportResponse> => {
	const params: IExportReplyRequest = {
		templateId,
		createDocument,
		timezone: getTimezone(),
		emails: email ? getEmailsArray(email) : undefined,
		dataParams: {
			objectId,
			commentId
		}
	};
	const response = await httpRequests.exportWithToken.post<IDefferedExportResponse>(
		`replies/export`,
		{
			params
		}
	);
	return response.data;
};
