import {memo} from 'react';
import {IConvertedWorkAcceptanceSpaceData} from '@tehzor/tools/interfaces/spaces/IConvertedWorkAcceptancesData';
import {StatsBorderCircle} from '../../components/StatsBorderCircle';

interface IWorkAcceptancesDiagramProps {
	width: number;
	height: number;
	radius: number;
	spaceId?: string;
	workAcceptances: IConvertedWorkAcceptanceSpaceData | undefined;
}

export const WorkAcceptancesDiagram = memo(
	({workAcceptances, width, height, radius, spaceId}: IWorkAcceptancesDiagramProps) => {
		const segments = workAcceptances?.items || [];
		const problems = workAcceptances?.problems || [];

		const segmentsCount = segments.length === 0 ? 1 : segments.length;
		// длина окружности
		const L = Math.PI * 2 * radius;
		// длина границы одного сегмента
		const x = L / segmentsCount;
		// остаток от сегмента
		const y = L - x;
		// координаты центра
		const cx = width - 10 - radius;
		const cy = height - 10 - radius;

		return (
			<>
				<circle
					cx={cx}
					cy={cy}
					r={radius}
					fill={segments.length > 0 ? "#FFFFFF" : "#EEF5FB"}
					stroke={segments.length > 0 ? "#FFFFFF" : "#EEF5FB"}
					strokeWidth="3"
				/>

				{segments.map(({color}, index) => (
					<circle
						// eslint-disable-next-line react/no-array-index-key
						key={`${spaceId}${index}`}
						cx={cx}
						cy={cy}
						r={radius / 2}
						fill="transparent"
						stroke={color}
						strokeWidth={radius}
						strokeDasharray={`${(x / 2).toString()} ${(y / 2).toString()}`}
						strokeDashoffset={(L - index * x + L / 4) / 2}
						opacity={0.26}
					/>
				))}

				{problems?.map(item => (
					<StatsBorderCircle
						key={item.key}
						width={width}
						height={height}
						radius={radius}
						item={item}
						color={item.color}
					/>
				))}
			</>
		);
	}
);
