import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {useCallback} from 'react';
import {WorkAcceptancesDiagram} from './WorkAcceptancesDiagram';

interface ISpaceDiagramProps {
	space: IConvertedSpace;
	width: number;
	height: number;
	name: string;
	onClick?: (space: IConvertedSpace) => void;
}

export const SpaceDiagram = ({space, width, height, name, onClick}: ISpaceDiagramProps) => {
	const {workAcceptancesForSchema} = space;

	const isTitleInline = name.length < 6;

	const handleClick = useCallback(() => {
		if (onClick) {
			onClick(space);
		}
	}, [onClick, space]);

	return (
		<div className='work-acceptances-space__status-wrap'>
			<svg
				width={width}
				height={height}
				className="work-acceptances-space__status"
				onClick={handleClick}
			>
				{/* тень */}
				<circle
					cx={width / 2}
					cy={height / 2}
					r={(width - 20) / 2}
					className="work-acceptances-space__status-shadow"
				/>
				{/* круговая диаграмма */}
				<WorkAcceptancesDiagram
					width={width}
					height={height}
					radius={(width - 20) / 2}
					workAcceptances={workAcceptancesForSchema}
					spaceId={space.id}
				/>
				{isTitleInline && (
					<text
						className="work-acceptances-space__number"
						x="50%"
						y="50%"
						dominantBaseline="middle"
						textAnchor="middle"
					>
						{name}
					</text>
				)}
			</svg>
			{!isTitleInline && (
				<text
					className="work-acceptances-space__number-outside"
					x="50%"
					y="50%"
					dominantBaseline="middle"
					textAnchor="middle"
				>
					{name}
				</text>
			)}
		</div>
	);
};
