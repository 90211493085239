import './WorkAcceptanceDateCell.less';
import {CellProps} from 'react-table';
import {useIsDesktop} from '@tehzor/ui-components/src/utils/mediaQueries';
import {EntityInfo, UserInfo} from '@tehzor/ui-components';
import {AcceptanceDateDisplayValue} from '@src/components/AcceptanceDateDisplayValue/AcceptanceDateDisplayValue';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {useUsersAsMap} from '@src/core/hooks/queries/users/hooks';
import {useWorkingGroupsAsMap} from '@src/core/hooks/queries/workingGroups/hooks';

const usersIcon = <i className="tz-users-24" />;
export const WorkAcceptanceDateCell = ({row}: CellProps<IListWorkAcceptance>) => {
	const {status, plannedAcceptanceDate, acceptors, acceptorsActiveGroup} = row.original;
	const {data: usersMap} = useUsersAsMap();
	const {data: groupsMap} = useWorkingGroupsAsMap();
	const isDesktop = useIsDesktop();

	return (
		<>
			{plannedAcceptanceDate ? (
				<AcceptanceDateDisplayValue
					className="problem-fix-cell-date"
					value={plannedAcceptanceDate}
					status={status}
				/>
			) : null}

			{acceptors?.length && usersMap ? (
				acceptors.map(userId => (
					<UserInfo
						key={userId}
						className="problem-fix-cell-user"
						user={usersMap[userId]}
						avatarSize={isDesktop ? '40' : '34'}
						avatarColoring="text"
					/>
				))
			) : acceptorsActiveGroup ? (
				<EntityInfo
					className="problem-fix-cell-user"
					title={groupsMap && groupsMap[acceptorsActiveGroup]?.name}
					icon={usersIcon}
					iconColor="#718198"
					backgroundColor="#FFDEB8"
				/>
			) : null}
		</>
	);
};
