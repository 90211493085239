import {CSSProperties, ComponentType, ReactNode, useMemo} from 'react';
import './SpacesBoard.less';
import classNames from 'classnames';
import {groupSpacesByFloor} from './utils/groupSpacesByFloor';
import {ISpaceComponent} from '../interfaces/ISpaceComponent';
import InlineButton from '../../buttons/LinkButton';
import {IConvertedSpace} from '@tehzor/tools/interfaces/spaces/IConvertedSpace';
import {Checkbox} from '../../inputs';

export interface ISpacesBoardProps {
	className?: string;
	style?: CSSProperties;
	data: IConvertedSpace[];
	title?: ReactNode;
	reverseFloorSort?: boolean;
	altNamesVisible?: boolean;
	choosenSpaces?: string[];
	chooseSpacesVisible?: boolean;
	SpaceComponent: ComponentType<ISpaceComponent>;
	checkListPopupContentSpaceNameTitle?: string;
	checkListPopupContentTranslationArr?: string[];
	spaceIndicatorsPopupContentTitle?: string;
	spaceIndicatorsPopupContentHide?: string;

	onTitleClick?: () => void;
	onTitleCheckBoxClick?: () => void;
	onSpaceClick?: (space: IConvertedSpace) => void;
	onSpaceChooseClick?: (space: object) => void;
}

export const SpacesBoard = (props: ISpacesBoardProps) => {
	const {
		className,
		style,
		data,
		title,
		onTitleClick,
		onTitleCheckBoxClick,
		reverseFloorSort,
		altNamesVisible,
		choosenSpaces,
		chooseSpacesVisible,
		SpaceComponent,
		checkListPopupContentSpaceNameTitle,
		checkListPopupContentTranslationArr,
		spaceIndicatorsPopupContentTitle,
		spaceIndicatorsPopupContentHide,
		onSpaceClick,
		onSpaceChooseClick
	} = props;
	const [floors, groups] = useMemo(
		() => groupSpacesByFloor(data, reverseFloorSort),
		[data, reverseFloorSort]
	);

	const isChecked = useMemo(
		() =>
			(data.length === choosenSpaces?.length || data.length > (choosenSpaces?.length || 0)) &&
			!!choosenSpaces?.length,
		[choosenSpaces, data]
	);

	const isSemiChecked = useMemo(
		() => data.length > (choosenSpaces?.length || 0) && !!choosenSpaces?.length,
		[choosenSpaces, data]
	);

	return (
		<div
			className={classNames(className, 'spaces-board')}
			style={style}
		>
			<div className="spaces-board__building">
				{title !== undefined && (
					<div className="spaces-board__header">
						{chooseSpacesVisible && (
							<Checkbox
								checked={isChecked}
								semiChecked={isSemiChecked}
								onChange={onTitleCheckBoxClick}
							/>
						)}
						<InlineButton
							onClick={onTitleClick}
							label={title}
							labelClassName="spaces-board__title"
							disabled={!onTitleClick}
						/>
					</div>
				)}
				{floors.map(floor => (
					<div
						key={floor}
						className={classNames("spaces-board__floor",{
							'spaces-board__floor-column': Number.isNaN(Number(floor))
						})}
					>
						<div className="spaces-board__floor-num-wrap">
							<div className="spaces-board__floor-num">{floor}</div>
						</div>

						<div className="spaces-board__spaces">
							{groups[floor].map(space => (
								<SpaceComponent
									key={space.id}
									space={space}
									disabled={!space.filtered}
									choosenSpaces={choosenSpaces || []}
									chooseSpacesVisible={chooseSpacesVisible ?? false}
									altNamesVisible={altNamesVisible}
									checkListPopupContentSpaceNameTitle={
										checkListPopupContentSpaceNameTitle
									}
									checkListPopupContentTranslationArr={
										checkListPopupContentTranslationArr
									}
									spaceIndicatorsPopupContentTitle={
										spaceIndicatorsPopupContentTitle
									}
									spaceIndicatorsPopupContentHide={
										spaceIndicatorsPopupContentHide
									}
									onClick={
										chooseSpacesVisible ? onSpaceChooseClick : onSpaceClick
									}
								/>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
