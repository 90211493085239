export enum EntityTypeId {
	PROBLEM = 'problem',
	INSPECTION = 'inspection',
	TASK = 'task',
	PROBLEM_REPLY = 'problemReply',
	WORK_ACCEPTANCE = 'work-acceptance',
	WARRANTY_CLAIM = 'warranty-claim',
	OBJECT = 'object',
	UNIT = 'unit',
	STRUCTURE = 'structure'
}

export interface IEntityType {
	id: EntityTypeId;
	name: string;
}
