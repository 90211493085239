import {memo} from 'react';
import EntitiesFilters, {ObjectsFilter} from '@src/components/EntitiesFilters';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractSpacesPageSettings} from '@src/store/modules/settings/pages/spaces/selectors';
import {StatusesFilter} from '@src/components/EntitiesFilters/components/StatusesFilter';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {ISpacesFiltersState} from '@src/store/modules/settings/pages/spaces/reducers/byPage';
import {SpaceTypeDecorationFilter} from '@src/components/EntitiesFilters/components/SpaceTypeDecorationFilter';
import {CategoriesSetFilter} from '@src/pages/ProblemsPage/components/filters/CategoriesSetFilter';
import {useTargetObjects} from '@src/core/hooks/queries/objects/hooks';
import {useExtractCheckListsAsArrayFilteredBySelectedSpaceType} from '@src/core/hooks/queries/checkLists/hooks';
import {useExtractRootCheckItems} from '@src/core/hooks/queries/checkItems/hooks';
import {useExtractCheckRecordStatusesByEntityTypeAsArray} from '@src/core/hooks/queries/checkRecordStatuses/hooks';
import {useExtractSpaceTypesAsArray} from '@src/core/hooks/queries/spaceTypes/hooks';
import {useProblemStatusesAsArray} from '@src/core/hooks/queries/problemStatuses/hooks';
import {useExtractWorkAcceptanceStatusesAsArray} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {useTranslation} from 'react-i18next';
import {useExtractSpaceStatusesAsArrayByStage} from '@src/core/hooks/queries/spaceStatusesSets/hooks';
import {dictionaryKeys} from '@src/constants/translations/dictionaryKeys';
import {useSpaceTypeDecorationsForSetsAsArray} from '@src/core/hooks/queries/spaceTypeDecorations/hooks';
import {useExtractSpaceTypeDecorationSetsForObjectAsArray} from '@src/core/hooks/queries/SpaceTypeDecorationSets/hooks';
import {UnitIndicatorsFilter} from '@src/components/EntitiesFilters/components/UnitIndicatorsFilter';
import {useActualUnitIndicatorsSetsExtended} from '@src/core/hooks/queries/unitIndicatorsSets/hooks';
import {useUnitIndicatorsAsArray} from '@src/core/hooks/queries/unitIndicators/hooks';

interface ISpacesFiltersProps {
	objectId: string;
}
const resetOnApplyValue = ['checkListCategory'];

const SpacesFilters = memo(({objectId}: ISpacesFiltersProps) => {
	const {t} = useTranslation();

	const {data: targetObjects} = useTargetObjects(objectId);
	const {state} = useEntitiesFiltersCtx<ISpacesFiltersState>();
	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const {data: spaceStatuses} = useExtractSpaceStatusesAsArrayByStage(
		objectId,
		pageSettings.stage
	);

	const {data: checkListRecordStatuses} =
		useExtractCheckRecordStatusesByEntityTypeAsArray('check-list');

	const {data: checkItemRecordStatuses} =
		useExtractCheckRecordStatusesByEntityTypeAsArray('check-item');

	const {data: problemStatuses} = useProblemStatusesAsArray();
	const {data: workAcceptanceStatuses} = useExtractWorkAcceptanceStatusesAsArray();
	const {data: indicators} = useUnitIndicatorsAsArray();
	const {data: types} = useExtractSpaceTypesAsArray();
	const {data: checkLists} = useExtractCheckListsAsArrayFilteredBySelectedSpaceType(objectId);
	const {data: typeDecorationSets} = useExtractSpaceTypeDecorationSetsForObjectAsArray(objectId);
	const typeDecorationSetIds = typeDecorationSets?.map(set => set.id) || [];

	const {data: typeDecorationsArray} =
		useSpaceTypeDecorationsForSetsAsArray(typeDecorationSetIds);

	const {data: setsWithIndicators} = useActualUnitIndicatorsSetsExtended(
		objectId,
		pageSettings.stage,
		false
	);

	const checkList =
		checkLists && checkLists.length === 1
			? checkLists[0].id
			: state?.checkListIds?.length === 1
			? state.checkListIds[0]
			: '';
	const {data: checkListCategory} = useExtractRootCheckItems(checkList);

	const objectsVisible = targetObjects?.length && targetObjects?.length > 1;

	const typesVisible = types;

	const statusesVisible =
		pageSettings.displayMode === SpacesDisplayMode.SCHEMA &&
		pageSettings.schemaView !== SpacesSchemaVariants.CHECK_LISTS;

	const problemStatusesVisible =
		pageSettings.displayMode === SpacesDisplayMode.SCHEMA && problemStatuses;

	const unitIndicatorsVisible =
		setsWithIndicators &&
		setsWithIndicators.length > 0 &&
		pageSettings.displayMode === SpacesDisplayMode.SCHEMA &&
		pageSettings.schemaView !== SpacesSchemaVariants.REPORTS;

	const workAcceptanceStatusesVisible =
		pageSettings.displayMode === SpacesDisplayMode.SCHEMA &&
		pageSettings.schemaView === SpacesSchemaVariants.WORK_ACCEPTANCES &&
		workAcceptanceStatuses;

	const typeDecorationVisible =
		pageSettings.displayMode === SpacesDisplayMode.SCHEMA &&
		typeDecorationsArray &&
		typeDecorationsArray.length > 0;

	const checkListIdsVisible =
		checkLists &&
		checkLists.length > 0 &&
		pageSettings.schemaView === SpacesSchemaVariants.CHECK_LISTS;

	const checkListStatusesVisible =
		pageSettings.displayMode === SpacesDisplayMode.SCHEMA &&
		pageSettings.schemaView === SpacesSchemaVariants.CHECK_LISTS &&
		checkListRecordStatuses;

	const checkListCategoryStatusesVisible =
		pageSettings.displayMode === SpacesDisplayMode.SCHEMA &&
		pageSettings.schemaView === SpacesSchemaVariants.CHECK_LISTS &&
		checkItemRecordStatuses;

	const checkListCategoryVisible =
		pageSettings.displayMode === SpacesDisplayMode.SCHEMA &&
		pageSettings.schemaView === SpacesSchemaVariants.CHECK_LISTS &&
		((checkLists && checkLists.length === 1) || state?.checkListIds?.length === 1) &&
		checkListCategory;

	const workAcceptanceCategoryVisible =
		pageSettings.displayMode === SpacesDisplayMode.SCHEMA &&
		pageSettings.schemaView === SpacesSchemaVariants.WORK_ACCEPTANCES;

	return (
		<EntitiesFilters
			objectId={objectId}
			entity="spaces"
		>
			{objectsVisible && (
				<ObjectsFilter
					value={state?.objects}
					targetObjects={targetObjects}
				/>
			)}

			{typesVisible && (
				<StatusesFilter
					label={t('spacesPage.spacesFilters.entitiesFilters.statusesFilter.types.label')}
					filterName="types"
					statuses={state?.types}
					allStatuses={types}
					translationDictionaryKey={dictionaryKeys.spaceTypes}
				/>
			)}

			{statusesVisible && spaceStatuses && (
				<StatusesFilter
					label={t(
						'spacesPage.spacesFilters.entitiesFilters.statusesFilter.statuses.label'
					)}
					filterName="statuses"
					statuses={state?.statuses}
					allStatuses={spaceStatuses}
				/>
			)}

			{problemStatusesVisible && (
				<StatusesFilter
					label={t(
						'spacesPage.spacesFilters.entitiesFilters.statusesFilter.problemStatuses.label'
					)}
					filterName="problemStatuses"
					statuses={state?.problemStatuses}
					allStatuses={problemStatuses}
					translationDictionaryKey={dictionaryKeys.problemStatuses}
				/>
			)}

			{workAcceptanceStatusesVisible && (
				<StatusesFilter
					label={t(
						'spacesPage.spacesFilters.entitiesFilters.statusesFilter.workAcceptanceStatuses.label'
					)}
					filterName="workAcceptanceStatuses"
					statuses={state?.workAcceptanceStatuses}
					allStatuses={workAcceptanceStatuses}
					translationDictionaryKey={dictionaryKeys.workAcceptanceStatuses}
				/>
			)}

			{unitIndicatorsVisible && indicators && (
				<UnitIndicatorsFilter
					indicators={indicators}
					indicatorSets={setsWithIndicators}
					label={t(
						'spacesPage.spacesFilters.entitiesFilters.statusesFilter.indicators.label'
					)}
					filterName="indicators"
					value={state?.indicators}
				/>
			)}

			{typeDecorationVisible && typeDecorationsArray && typeDecorationSets && (
				<SpaceTypeDecorationFilter
					label={t(
						'spacesPage.spacesFilters.entitiesFilters.statusesFilter.typeDecoration.label'
					)}
					filterName="typeDecoration"
					value={state?.typeDecoration}
					typeDecorations={typeDecorationsArray}
					typeDecorationSets={typeDecorationSets}
				/>
			)}

			{checkListIdsVisible && (
				<StatusesFilter
					label={t(
						'spacesPage.spacesFilters.entitiesFilters.statusesFilter.checkListIds.label'
					)}
					filterName="checkListIds"
					statuses={state?.checkListIds}
					allStatuses={checkLists}
					resetOnApply={resetOnApplyValue}
				/>
			)}

			{checkListStatusesVisible && (
				<StatusesFilter
					label={t(
						'spacesPage.spacesFilters.entitiesFilters.statusesFilter.checkListStatuses.label'
					)}
					filterName="checkListStatuses"
					statuses={state?.checkListStatuses ?? ([] as string[])}
					allStatuses={checkListRecordStatuses}
					translationDictionaryKey={dictionaryKeys.checkListStatuses}
				/>
			)}

			{checkListCategoryStatusesVisible && (
				<StatusesFilter
					label={t(
						'spacesPage.spacesFilters.entitiesFilters.statusesFilter.checkListCategoryStatuses.label'
					)}
					filterName="checkListCategoryStatuses"
					statuses={state?.checkListCategoryStatuses}
					allStatuses={checkItemRecordStatuses}
					translationDictionaryKey={dictionaryKeys.checkListStatuses}
				/>
			)}

			{checkListCategoryVisible && (
				<StatusesFilter
					label={t(
						'spacesPage.spacesFilters.entitiesFilters.statusesFilter.checkListCategory.label'
					)}
					filterName="checkListCategory"
					statuses={state?.checkListCategory}
					allStatuses={checkListCategory}
				/>
			)}

			{workAcceptanceCategoryVisible && (
				<CategoriesSetFilter
					objectsIds={state.objects ? state.objects : [objectId]}
					categories={state?.categories}
				/>
			)}
		</EntitiesFilters>
	);
});

export default SpacesFilters;
