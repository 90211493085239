import {CellProps} from 'react-table';
import {IListWorkAcceptance} from '@tehzor/tools/interfaces/workAcceptances/IListWorkAcceptance';
import {format} from 'date-fns';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {ru} from 'date-fns/locale';
import {WorkAcceptanceStatusCell} from './WorkAcceptanceStatusCell';
import {EntityInfo} from '@tehzor/ui-components';
import {useExtractWorkAcceptanceStatusesById} from '@src/core/hooks/queries/workAcceptanceStatuses/hooks';
import {useExtractWorkAcceptanceTypesById} from '@src/core/hooks/queries/workAcceptanceTypes/hooks';
import {useExtractCategoryById} from '@src/core/hooks/queries/categories/hook';
import {useFilteredStructuresListAsArray} from '@/entities/Structures';

const workAcceptanceIcon = <i className="tz-work-acceptance-28" />;

export const MobileWorkAcceptanceCell = (props: CellProps<IListWorkAcceptance>) => {
	const {row} = props;
	const workAcceptance: IListWorkAcceptance = row.original;
	const {data: structures} = useFilteredStructuresListAsArray(
		workAcceptance.objectId,
		workAcceptance.structureIds
	);

	const {data: typesMap} = useExtractWorkAcceptanceTypesById();
	const {data: statusesMap} = useExtractWorkAcceptanceStatusesById();
	const {data: category} = useExtractCategoryById(workAcceptance.categoryId);

	const subTitle =
		workAcceptance.plannedAcceptanceDate &&
		`срок до ${format(workAcceptance.plannedAcceptanceDate, dateTimeCommaSeparatedFormat, {
			locale: ru
		})}`;
	const iconColor = statusesMap && statusesMap[workAcceptance.status]?.color;
	const title = `Приёмка работ №${workAcceptance.number}`;

	return (
		<div className="structure-page__work-acceptance-cell">
			<EntityInfo
				title={title}
				subTitle={subTitle}
				icon={workAcceptanceIcon}
				iconColor={iconColor}
			/>
			<div className="structure-page__work-acceptance-cell_data">
				<div>{category?.name}</div>
				{workAcceptance.type && (
					<div>{`Тип приёмки: ${typesMap && typesMap[workAcceptance.type].name}`}</div>
				)}

				{structures && <div>{structures.map(str => str.name).join(', ')}</div>}
			</div>

			<div className="structure-page__work-acceptance-cell_data">
				<WorkAcceptanceStatusCell {...props} />
			</div>
		</div>
	);
};
