import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IDeleteReplyResponse {success: true};
/**
 * Удаляет ответ
 *
 * @param objectId id объекта
 * @param commentId id комментария
 */
export async function makeReplyDeleteRequest(objectId: string, commentId: string) {
	const response = await httpRequests.withToken.delete<IDeleteReplyResponse>(
		`replies/${objectId}/${commentId}`
	);
	return response.data;
}
