import {Suspense} from 'react';
import classNames from 'classnames';
import {useObjectSectionsMenu} from '@src/core/hooks/useObjectSectionsMenu';
import {useObjectAppHeader} from '@src/components/AppHeader/hooks/useObjectAppHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {SpacesDisplayMode} from '@src/interfaces/SpacesDisplayMode';
import {LegendDialog} from './legend';
import {
	extractSelectedSpacesForObjectIds,
	extractSpacesPageSettings
} from '@src/store/modules/settings/pages/spaces/selectors';
import MobileSchemas from './schema/Schemas.mobile';
import MobileTable from './table/Table.mobile';
import {MobileFiltersLinkBtn} from '@src/components/MobileFiltersLinkBtn';
import {MobileDisplayModeSwitch} from './DisplayModeSwitch.mobile';
import {SpaceTypeMenu} from './typeFilter/SpaceTypeMenu';
import MobileActions from './actions/Actions.mobile';
import {MobileSelectionClearing} from './selection/SelectionClearing.mobile';
import {useEntitiesFiltersCtx} from '@src/components/EntitiesFilters/utils/entitiesFiltersCtx';
import {FloorSort} from './sort/FloorSort';
import PageBreadcrumbs from './PageBreadcrumbs';
import {SpacesSchemaVariants} from '@src/interfaces/SpacesSchemaVariants';
import {MobileTypesFilterLoader} from '@tehzor/ui-components/src/components/loaders/skeletons/MobileTypesFilterLoader';
import {MobileSchemaLoader} from '@tehzor/ui-components/src/components/loaders/skeletons/MobileSchemaLoader';
import {useCurrentTreeObject, useTargetObjects} from '@src/core/hooks/queries/objects/hooks';
import {ProcessVisibilityFilter} from '@src/pages/SpacesPage/components/filters/ProcessVisibilityFilter';
import {useSpacesSelection} from '../hooks/useSpacesSelection';
import MobileSelectAllSpaces from './actions/SelectAllSpaces.mobile';
import {SpaceChoosingToolbarMobile} from './toolbar/SpaceChoosingToolbar.mobile';
import {useMultipleEntitiesAddingPermissions} from '@src/core/hooks/permissions/useMultipleEntitiesAddingPermissions';

interface IMobileProps {
	objectId: string;
}

const Mobile = ({objectId}: IMobileProps) => {
	const {displayMode, schemaView, selectedSubObjects, selected} = useAppSelector(s =>
		extractSpacesPageSettings(s, objectId)
	);

	const pageSettings = useAppSelector(s => extractSpacesPageSettings(s, objectId));
	const {data: targetObjects} = useTargetObjects(objectId, true);

	const filteredObjects = pageSettings.filters.objects;

	// Селектор для получения данных по множественному выбору помещений
	const {singleObjectId, typeCounts, nonEmptySelectedCount} = useAppSelector(state =>
		extractSelectedSpacesForObjectIds(state, objectId, targetObjects || [])
	);
	// Видимость режима выбора множества помещений
	const {isChoosingVisible} = useSpacesSelection(objectId);

	const {state} = useEntitiesFiltersCtx();
	const sectionsMenu = useObjectSectionsMenu(objectId);

	const object = useCurrentTreeObject(objectId);

	const {canAddMultipleEntities} = useMultipleEntitiesAddingPermissions();

	useObjectAppHeader(
		objectId,
		{
			title: isChoosingVisible ? selected.length.toString() : object?.name,
			sectionsMenu,
			mobileLeftButton: isChoosingVisible ? (
				<MobileSelectionClearing objectId={objectId} />
			) : undefined,
			mobileRightButtons:
				isChoosingVisible && displayMode === SpacesDisplayMode.SCHEMA ? (
					<MobileSelectAllSpaces
						objectId={objectId}
						targetObjects={targetObjects || []}
					/>
				) : (
					<MobileActions objectId={objectId} />
				)
		},
		[objectId, selectedSubObjects, isChoosingVisible, displayMode]
	);

	return (
		<div
			className={classNames('page-cont', 'spaces-page', {
				'page-cont_extra-top-margin': sectionsMenu.length
			})}
		>
			<PageBreadcrumbs objectId={objectId} />
			<div className="spaces-page__m-controls-bar">
				<MobileDisplayModeSwitch objectId={objectId} />

				<div className="spaces-page__space-type-menu-btn-wrap">
					<Suspense fallback={<MobileTypesFilterLoader />}>
						{displayMode === SpacesDisplayMode.SCHEMA && targetObjects && (
							<SpaceTypeMenu
								objectId={objectId}
								targetObjects={targetObjects}
								filteredObjects={filteredObjects}
							/>
						)}
					</Suspense>
				</div>

				<LegendDialog />

				<MobileFiltersLinkBtn
					state={state}
					objectId={objectId}
					entity="spaces"
				/>
			</div>

			<div className="spaces-page__filter-bar">
				<ProcessVisibilityFilter objectId={objectId} />
				{displayMode === SpacesDisplayMode.SCHEMA &&
					schemaView !== SpacesSchemaVariants.REPORTS && (
						<FloorSort objectId={objectId} />
					)}
			</div>

			{displayMode === SpacesDisplayMode.SCHEMA && targetObjects && (
				<Suspense fallback={<MobileSchemaLoader />}>
					<MobileSchemas
						objectId={objectId}
						targetObjects={targetObjects}
					/>
				</Suspense>
			)}
			{displayMode === SpacesDisplayMode.LIST && <MobileTable objectId={objectId} />}
			{isChoosingVisible && canAddMultipleEntities && (
				<SpaceChoosingToolbarMobile
					objectId={singleObjectId as string}
					isBtnDisabled={
						!singleObjectId || typeCounts !== 1 || nonEmptySelectedCount !== 1
					}
				/>
			)}
		</div>
	);
};

export default Mobile;
