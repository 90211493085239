import {Suspense} from 'react';
import {SingleSchema} from './SingleSchema';
import classNames from 'classnames';
import {SchemaLoader} from '@tehzor/ui-components/src/components/loaders/skeletons/SchemaLoader';

interface ISchemaProps {
	objectId: string;
	slicedObjects: string[];
	targetObjects?: string[];
}

export const Schema = ({objectId, slicedObjects, targetObjects}: ISchemaProps) => (
	<div
		className={classNames('spaces-page__schema-list', {
			'spaces-page__schema-list_single-col': slicedObjects.length === 1
		})}
	>
		{slicedObjects.map(id => (
			<Suspense
				key={id}
				fallback={<SchemaLoader />}
			>
				<SingleSchema
					objectId={id}
					isTitleClickable={id !== objectId}
					targetObjects={targetObjects}
				/>
			</Suspense>
		))}
	</div>
);
